/*globals $*/
'use strict';

const getValidationFunctions = () => import("./validate");

$( document ).ready(function() {
    
    $('#angrup__newsletter-form #angrup__newsletter-form_submit-button').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateNewsletterForm();
        });
    });

});

